export default Object.freeze({
    title: {
        en: 'Computer Based Training Software<br/>' +
            '                            X-Ray Image Assessment Technology (“XIAT”)',
        ru: 'Программа Компьютеризированного Обучения Операторов Досмотра<br/>' +
            'X-Ray Image Assessment Technology (“XIAT”)',
    },
    descriptionTitle: {
        en: 'DESCRIPTION',
        ru: 'ОПИСАНИЕ',
    },
    descriptionText: {
        en: 'Computer simulator «XIAT» (X-Ray Image Assessment Technology) designed for training, recurrent training and testing of X-Ray screeners, performing screening of luggage, hold baggage, cargo and mail.\n' +
            '\n<br/><br/>' +
            'This software gives the possibility not only to train new aviation security personnel, but also is appropriate for certified screeners periodic recurrent training and testing.\n' +
            '\n<br/><br/>' +
            'XIAT simulates the operation of real X-ray equipment, with the possibility of applying basic modes. This approach allows operators (screeners) to move from theory to practice as quickly as possible and effectively analyze images on X-ray monitor at the workplace.' +
            '\n<br/><br/>' +
            'The computer simulator "XIAT" can be used for training and testing personnel of entities where there are screening procedures using X-ray machines to ensure integrated security (border, customs, law enforcement agencies, security organizations to ensure the security of critical infrastructure, railway / metro, hotels, stadiums, etc.).',
        ru: 'Компьютерный тренажер «XIAT» (X-Ray Image Assessment Technology) предназначен для подготовки, переподготовки и тестирования операторов рентгеновских установок, производящих досмотр ручной клади, багажа, грузов и почты.\n' +
            '\n<br/><br/>' +
            'Данная программа позволяет не только обучать новый персонал по авиационной безопасности, но также подходит для периодического обучения и тестирования сертифицированного персонала, занимающегося досмотром.\n' +
            '\n<br/><br/>' +
            'XIAT имитирует работу реальной рентгеновской установки, с возможностью применяя основных режимов. Такой подход позволяет операторам максимально быстро перейти от теории к практике и эффективно анализировать изображения на мониторе рентгеновской установки на рабочем месте.'+
            '\n<br/><br/>' +
            'Компьютерный тренажер «XIAT» может применяться для подготовки и тестирования персонала субъектов, где есть процедуры досмотра с помощью рентгеновских аппаратов для обеспечения комплексной безопасности (граница, таможня, силовые структуры, охранные организации для обеспечения безопасности критической инфраструктуры, ЖД/метро, отели, стадионы, и т.д.).'
    },
    mainTechnicalTitle: {
        en: 'MAIN TECHNICAL CHARACTERISTICS',
        ru: 'ОСНОВНЫЕ ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ',
    },
    mainTechnicalXiatDatabase: {
        en: 'XIAT Database',
        ru: 'В базе XIAT',
    },
    descriptionText1: {
        en: 'More than 6000 elements of real passenger’s bags of different structure, shape and content.',
        ru: 'Более 6000 элементов сумок реальных пассажиров различной структуры, формы и содержания.',
    },
    descriptionText2: {
        en: 'More than 1000 images of prohibited items (in different projections). All items correspond to the list of prohibited items (Doc 8973 ICAO) - for example: pistols, firearms; stunning devices; explosive and incendiary substances and devices; working tools, etc.',
        ru: 'Более 1000 изображений запрещенных предметов (в разных проекциях). Все предметы соответствуют перечню запрещенных предметов (Doc 8973 ICAO) – например: пистолеты, огнестрельное оружие; устройства шокового действия; взрывчатые и зажигательные вещества и устройства; рабочие инструменты и т.д.',
    },
    descriptionText3: {
        en: 'Real photographs of prohibited items (available for students during training).',
        ru: 'Реальные фотографии запрещенных предметов (доступны ученикам во время обучения).',
    },
    descriptionText4: {
        en: 'Standard sessions for operator training (full complex of training) + final testing (exam).',
        ru: 'Эталонные сессии для обучения операторов досмотра (полный комплекс обучения) + итоговое тестирование (экзамен).',
    },
    descriptionTitle2: {
        en: 'Possibility to apply the basic modes of real X-ray equipment.',
        ru: 'Возможность применять основные режимы реальной рентгеновской установки.',
    },
    descriptionText2_1: {
        en: 'OM – organic',
        ru: 'OM – органика',
    },
    descriptionText2_2: {
        en: 'IM – non organic',
        ru: 'IM – неорганика',
    },
    descriptionText2_3: {
        en: 'BW – black/white',
        ru: 'BW – черно-белый',
    },
    descriptionText2_4: {
        en: 'IN – inversion',
        ru: 'IN – инверсия',
    },
    descriptionText2_5: {
        en: 'Objects contrast changing',
        ru: 'Изменение контрастности объектов',
    },
    descriptionText2_6: {
        en: 'Zooming',
        ru: 'Увеличение',
    },
    descriptionTitle3: {
        en: 'Testing',
        ru: 'Тестирование',
    },
    descriptionText3_1: {
        en: 'Mastery tests of diverse complexity after the main training sessions.',
        ru: 'Текущие тесты различной сложности после основных учебных сессий.',
    },
    descriptionText3_2: {
        en: 'Final test (examination session) – random approach allows to evaluate each student with better efficiency and objectivity.',
        ru: 'Итоговый тест (экзаменационная сессия) – рендомный подход позволяет эффективно и объективно оценить каждого ученика.',
    },
    descriptionText3_3: {
        en: 'Saving student’s results in database.',
        ru: 'Сохранение результатов ученика в базе данных.',
    },
    descriptionText3_4: {
        en: 'Possibility for student to access test results for analysis.',
        ru: 'Возможность доступа ученика к результатам тестов для анализа.',
    },
    descriptionText3_5: {
        en: 'Possibility for instructors/administrators to access test results of each student.',
        ru: 'Возможность доступа инструктора/администратора клиента к результатам тестов всех учеников.',
    },
    descriptionTitle4: {
        en: 'Training',
        ru: 'Обучение',
    },
    descriptionText4_1: {
        en: 'Option of getting a hint.',
        ru: 'Возможность получение подсказки.',
    },
    descriptionText4_2: {
        en: 'Analysis of prohibited items in different projections.',
        ru: 'Изучение запрещенных предметов в различных проекциях.',
    },
    descriptionText4_3: {
        en: 'Opportunity to review a real photo of a prohibited item in the bag.',
        ru: 'Возможность посмотреть реальное фото запрещенного предмета, находящегося в сумке.',
    },
    descriptionText4_4: {
        en: 'True/False hint after decision making.',
        ru: 'Подсказка Верно/Неверно после принятия решения.',
    },
    descriptionText4_5: {
        en: 'Students’ access to the report.',
        ru: 'Доступ к отчету для ученика.',
    },
    optionsTitle: {
        en: 'OPTIONS',
        ru: 'ДОПОЛНИТЕЛЬНЫЕ ВОЗМОЖНОСТИ',
    },
    optionsText1: {
        en: 'The ability to create bags of diverse complexity for training and testing by the instructors/administrators of the customer.',
        ru: 'Возможность создания сумок различной сложности для обучения и тестирования инструкторами/администраторами клиента.',
    },
    optionsText2: {
        en: 'Assignment of ready-made bags/lessons/sessions to different groups of students.',
        ru: 'Присвоение готовых сумок/уроков/сессий к различным группам учеников.',
    },
    optionsText3: {
        en: 'Entering the password (identifier) for the student/instructor.',
        ru: 'Введения пароля (идентификатора) для ученика/инструктора.',
    },
    optionsText4: {
        en: 'Possibility to choose the interface language.',
        ru: 'Возможность выбора языка интерфейса.',
    },
    optionsText5: {
        en: 'Ability to save a report for each student.',
        ru: 'Возможность сохранения отчета по каждому ученику.',
    },
    solutionTitle: {
        en: 'SOLUTION FOR OUR POTENTIAL CUSTOMERS',
        ru: 'ПРЕДЛОЖЕНИЯ ПОТЕНЦИАЛЬНЫМ КЛИЕНТАМ',
    },
    solutionText1: {
        en: 'The ability to use the interface language convenient for the customer.',
        ru: 'Возможность использования языка интерфейса удобного для клиента.',
    },
    solutionText2: {
        en: 'Annual update of the database.',
        ru: 'Ежегодное обновление базы данных.',
    },
    solutionText3: {
        en: 'Individual and flexible approach to each customer.',
        ru: 'Индивидуальный и гибкий подход к каждому клиенту.',
    },
    solutionText4: {
        en: 'Training of instructors of the customer to work with XIAT.',
        ru: 'Обучение инструкторов клиента работе с XIAT.',
    },
    contactsTitle: {
        en: 'CONTACTS',
        ru: 'КОНТАКТЫ',
    },
    magicRayLLC: {
        en: 'LIMITED LIABILITY COMPANY МAGIC RAY (МAGIC RAY LLC)',
        ru: 'ООО ""МЕДЖИК РЕЙ"',
    },
    contactsEmail: {
        en: 'E-mail',
        ru: 'E-mail',
    },
    contactsPhone: {
        en: 'Phone',
        ru: 'Телефон',
    },
});
