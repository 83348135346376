import React from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Carousel } from 'react-responsive-carousel';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import GroupIcon from '@material-ui/icons/Group';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import ContactsIcon from '@material-ui/icons/Contacts';
import EmailIcon from '@material-ui/icons/Email';

import "react-responsive-carousel/lib/styles/carousel.min.css";

import './App.css';

import locale from './locale'
import styles from './styles'
import logo from './assets/logo_medium.png';
import f1 from './assets/f1.jpg';
import f2 from './assets/f2.jpg';
import f3 from './assets/f3.jpg';
import f4 from './assets/f4.jpg';
import f5 from './assets/f5.jpg';
import f6 from './assets/f6.jpg';
import f7 from './assets/f7.jpg';
import image1 from './assets/image1.png';
import image2 from './assets/image2.png';
import image3 from './assets/image3.png';
import image4 from './assets/image4.jpg';
import image5 from './assets/image5.jpg';

class Pricing extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            lang: 'en'
        }
    }

    componentDidMount() {
        const l = (new URL(window.location.href)).searchParams.get("lang");
        this.setState({lang: l === 'en' ? 'en' : l === 'ru' ? 'ru' : 'en'});
    }

    getText = (key) => {
        if (locale[key]) {
            return locale[key][this.state.lang];
        } else {
            return '-';
        }
    };

    render() {
        const {classes} = this.props;
        const {getText} = this;

        const descriptionUmanes = {
          1: [image1],
          2: [image2, image3],
          3: [image4, image5],
          4: []
        };

        return (
            <div>
                <AppBar>
                    <Toolbar >
                        <Button color="inherit" href="?lang=en">EN</Button>
                        <Button color="inherit" href="?lang=ru">RU</Button>
                    </Toolbar>
                </AppBar>

                <div className="main-container">
                    <div >
                        <div className={classes.heroContent}>
                            <img src={logo}/>
                            <Typography dangerouslySetInnerHTML={{__html: getText('title')}} variant="h6" align="center" color="textSecondary" component="p"/>
                        </div>
                    </div>
                    <Paper elevation={1} className="paper">
                        <Typography variant="h4" align="center" color="textSecondary" component="p">
                            {getText('descriptionTitle')}
                        </Typography>
                        <Grid container spacing={24}>
                            <Grid item sm={6} xs={12}>
                                <p dangerouslySetInnerHTML={{__html: getText('descriptionText')}}/>

                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Carousel autoPlay showThumbs={false}>
                                    <div>
                                        <img src={f1} />
                                    </div>
                                    <div>
                                        <img src={f2} />
                                    </div>
                                    <div>
                                        <img src={f3} />
                                    </div>
                                    <div>
                                        <img src={f4} />
                                    </div>
                                    <div>
                                        <img src={f5} />
                                    </div>
                                    <div>
                                        <img src={f6} />
                                    </div>
                                    <div>
                                        <img src={f7} />
                                    </div>
                                </Carousel>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} className="paper">
                        <Typography variant="h4" align="center" color="textSecondary" component="p">
                            {getText('mainTechnicalTitle')}
                        </Typography>
                        <div>
                            <Typography variant="h6" align="center" color="textSecondary" component="p">
                                {getText('mainTechnicalXiatDatabase')}
                            </Typography>
                            {[1, 2, 3, 4].map(n => (
                                <Grid container spacing={24}>
                                    <Grid item sm={5} xs={12} className="text-grid">
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar className="icon">
                                                    <CheckCircleIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                style={{textAlign: 'justify'}}
                                                primary={getText('descriptionText' + n)}
                                            />
                                        </ListItem>

                                    </Grid>
                                    <Grid item sm={7} xs={12} className="image-grid">
                                        {descriptionUmanes[n].map(image => (
                                            <img src={image}/>
                                        ))}
                                    </Grid>
                                </Grid>
                            ))}
                            <Divider className="divider"/>
                            <Typography variant="h6" align="center" color="textSecondary" component="p">
                                {getText('descriptionTitle2')}
                            </Typography>
                            <List>
                                {[1, 2, 3, 4, 5, 6].map(n => (
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className="icon">
                                                <SettingsIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={getText('descriptionText2_' + n)}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            <Divider className="divider"/>
                            <Typography variant="h6" align="center" color="textSecondary" component="p">
                                {getText('descriptionTitle3')}
                            </Typography>
                            <List>
                                {[1, 2, 3, 4, 5].map(n => (
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className="icon">
                                                <PlaylistAddCheckIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={getText('descriptionText3_' + n)}
                                        />
                                    </ListItem>
                                ))}
                            </List><Divider className="divider"/>
                            <Typography variant="h6" align="center" color="textSecondary" component="p">
                                {getText('descriptionTitle4')}
                            </Typography>
                            <List>
                                {[1, 2, 3, 4, 5].map(n => (
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className="icon">
                                                <GroupIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={getText('descriptionText4_' + n)}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Paper>
                    <Paper elevation={1} className="paper">
                        <Typography variant="h4" align="center" color="textSecondary" component="p">
                            {getText('optionsTitle')}
                        </Typography>
                        <List>
                            {[1, 2, 3, 4, 5].map(n => (
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="icon">
                                            <ViewQuiltIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={getText('optionsText' + n)}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                    <Paper elevation={1} className="paper">
                        <Typography variant="h4" align="center" color="textSecondary" component="p">
                            {getText('solutionTitle')}
                        </Typography>
                        <List>
                            {[1, 2, 3, 4].map(n => (
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="icon">
                                            <ContactsIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={getText('solutionText' + n)}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                    <AppBar position="fixed" color="primary" className={classes.appBar}>
                        <Toolbar >
                            <Grid container spacing={24}>
                                <Grid item sm={6} xs={12}>
                                    <div className='footer-item'  style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                        <EmailIcon style={{ fontSize: 14 }}/>:
                                        <a href='mailto:info@xiat.org' style={{color: '#fff'}}>info@xiat.org</a> <br/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Pricing);
